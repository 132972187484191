import React, { useState, useRef } from 'react';
import './VehicleItem.scss';

import { Modal } from '../../../core/components';


const VehicleItem = ({ lang, item, onVehicleItemClick }) => {
    const [fullImage, setFullImage] = useState();
    const handleItemClick = () => {
        onVehicleItemClick(item);
    };

    const showFullImage = useRef();

    const handleItemPhotoClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setFullImage(item.imageUrl);
        showFullImage.current.toggleModal(true);
    };
    const handleModalClose = (e) => {
        setFullImage(null);
        showFullImage.current.toggleModal(false);
    };

    return (
        <>
            <div className="cx-vehicles-item" onClick={handleItemClick}>
                <div className="cx-vehicles-item-image" onClick={handleItemPhotoClick}>
                    {item.imageUrl ? <img src={`${item.imageUrl}&s=125x150`} alt="Vehicle primary" /> : <i className="icon-Vehicle" />}
                </div>
                <div className="cx-vehicles-item-info">
                    <h2>
                        <span className="Vehicle-name"> {item.year} {item.make} {item.model}</span>
                    </h2>
                    <p> {item.vin}</p>
                    <p><label>{lang.color}</label>: {item.color}</p>
                    <p><label>{lang.mileage}</label>: {item.mileage}</p>

                </div>
                <Modal ref={showFullImage} onModalClosed={handleModalClose}>
                    {fullImage ? <img src={fullImage} alt="Vehicle full size" /> : null}
                </Modal>
            </div>
        </>
    );
};

export default VehicleItem;
