const data = {
  en: {
    dailyTools: 'Daily Tools',

    // ==================================
    // Common
    changeLanguage: 'Change Language',
    dashboard: 'Dashboard',
    notFound: 'Not Found',
    myProfile: 'My Profile',
    profile: 'Profile',
    register: 'Register',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    logIn: 'Log In',
    logOut: 'Log Out',
    contact: 'Contact',
    about: 'About',
    address: 'Address',

    general: 'General',
    description: 'Description',
    home: 'Home',
    save: 'Save',
    add: 'Add',
    cancel: 'Cancel',
    total: 'Total',
    from: 'From',
    to: 'To',
    all: 'All',
    category: 'Category',
    icon: 'Icon',
    building: 'Building',
    bus: 'Bus',
    plane: 'Plane',
    restaurants: 'Restaurants',
    min: 'min',
    new: 'New',
    tools: 'Tools',
    showing: 'Showing',
    of: 'of',

    lastUpdated: 'last updated ',
    uLastUpdated: 'Last updated ',
    updated: 'updated!',
    saved: 'saved!',
    deleted: 'deleted!',

    dueDate: 'Due Date',
    addDueDate: 'Add Due Date',
    today: 'Today',
    tomorrow: 'Tomorrow',
    nextWeek: 'Next Week',
    pickADate: 'Pick a Date',
    notifyMe: 'Notify Me',

    default: 'Default',
    isDefault: 'Is default',
    setAsDefault: 'Set as default',
    actions: 'Actions',
    whatIsOnYourMind: 'What is on your mind ...',
    unexpectedErrorOcurred: 'Unexpected application error has occurred',
    by: 'by',
    loadMore: 'Load More',
    deleteConfirmationMessage: 'Are you sure you want to delete ',
    comment: 'Comment',

    continue: 'continue',
    moved: 'moved',

    // Navigation
    siteSearch: 'Site Search',
    administration: 'Administration',

    // Common.Validation.Messages
    validationFieldRequired: 'Field required',

    // Notifications
    notifications: 'Notifications',
    notificationConfirmationLoadingError: 'Unable to load content. Sorry for the inconvenience.',

    // Share Content
    share: 'Share',
    shared: 'Shared',
    sharedWithOthers: 'Shared with others.',
    sharedWithMe: 'Shared with me.',
    sharedSuccessMessage: 'Content shared!',
    shareInstructions: 'Fill out the information below to share your content.',
    accessLevel: 'Access Level',

    // Profile
    email: 'Email',
    name: 'Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    phone: 'Phone',
    title: 'Title',
    aboutMe: 'About Me',
    language: 'Language',
    dropProfilePhoto: 'Drag and drop to update profile photo, or click to select file',
    profilePhotoUpdated: 'Profile photo updated!',

    // Units
    mile: 'Mile',
    miles: 'Miles',
    kilometer: 'Kilometer',
    kilometers: 'Kilometers',
    meter: 'Meter',
    meters: 'Meters',
    feet: 'Feet',
    sqFt: 'sqFt',
    sqMt: 'sqMt',
    squareFeet: 'Square Feet',

    firstHad: 'First Had',

    // Security
    youHaveBeenLoggedOutDueToInactivity: 'You have been logged out due to inactivity.',
    account: 'Account',
    createAccount: 'Create Account',
    creatingAccount: 'Creating Account ...',
    accountCreated: 'Account Created!',
    accountValidateCode: 'Account Activation',
    accountActivationCompleted: 'Congratulations, Account Activation Completed!',
    accountActivationCode: 'Account Activation Code',
    activationCode: 'Activation Code',
    requestNewCode: 'Request New Code',
    newTemporaryCodeSent: 'New code has been sent.',
    createPassword: 'Create Password',
    passwordRules: 'Password Guidelines',
    passwordDoNotMatch: 'Confirmation Password does not match',
    welcomeToDakityInstructions: 'Welcome to Dakity. Please enter the code sent to your email in the input box below.',
    registration: 'Registration',
    login: 'Login',
    accountLogin: 'Account Login',
    accountSettings: 'Account Settings',
    accountClickRegister: 'Do not have an account yet? Click to register.',
    password: 'Password',
    youForgotPasswordTitle: 'You forgot your password?',
    youForgotPasswordInstructions:
      'We would be happy to help you. \nPlease enter the information below so you can reset the password for your account.',
    confirmPassword: 'Confirm Password',
    recoverPassword: 'Recover Password',
    recoverPasswordEmailInstructions:
      'A validation code has been sent to your email. Please check your email to complete recovery process.',
    totalPasswords: 'Total Passwords',
    forgotPassword: 'Forgot Password?',
    sessionEndedRedirecting: 'Session ended, redirecting to home page...',
    accessingSystem: 'Loading session...',
    requestPasswordReset: 'Request Password Reset',
    emailValidation: 'Email Validation',
    validationRequiredEmail: 'Email required!',
    validationRequiredPassword: 'Password Required',
    validationCustomEmailInvalid: 'Invalid email address',
    validationMinLengthPassword: 'Minimum password length is {0}',
    usernameCopiedToClipboard: 'Username copied to clipboard',
    passwordCopiedToClipboard: 'Password copied to clipboard',

    unableToValidateUser: 'Unable to validate user.',

    learnMoreAboutHowItWorks: 'Learn more about how LISTATEC works',
    howListatecWorks: 'How LISTATEC Works?',
    howListatecWorksSub: 'Search for types of commercial property and location.',
    refineSearch: 'Refine Search',
    refineSearchSub: 'Look for different ways to enhanced your search.',
    compareOrContact: 'Add Compare Contact',
    compareOrContactSub: 'Add them to your list, compare them for better analysis or contact agent.',
    searchCompareContact: 'Search Compare Contact',
    noListingsFound: 'No listings found.',

    saveSearch: 'Save Search',
    map: 'Map',
    list: 'List',
    goBackToListings: 'Go back to listings',
    yearly: 'Yearly',
    monthly: 'Monthly',
    images: 'Images',
    details: 'Details',
    information: 'Information',
    nearbyTransportation: 'Nearby/Transportation',
    brokerAgent: 'Broker Agent',
    brokerAgents: 'Broker Agents',

    noDataFound: 'No data found.',
    facilities: 'Facilities',
    drive: 'Drive',
    distance: 'Distance',
    distanceUnit: 'Distance Unit',
    latestCommercialListings: 'Latest Commercial Listings',
    expand: 'Expand',
    close: 'Close',
    compare: 'Compare',
    moreInfo: 'More Information',
    favorites: 'Favorites',
    dashboardConfiguration: 'Dashboard Configuration',
    edit: 'Edit',
    delete: 'Delete',
    connections: 'Connections',
    quickActions: 'Quick Actions',
    leads: 'Leads',
    additionalCriteria: 'Additional Criteria',

    addListing: 'Add Listing',
    listingSearch: 'Listing Search',
    listings: 'Listings',

    connect: 'Connect',
    reports: 'Reports',

    listingProfile: 'Listing',
    example: 'Example: ',

    // Listings  ===================================

    // = Dashboard
    listingDashboard: 'Listing Dashboard',
    trendingListings: 'Trending Listings',

    // Listing Search / Summary
    propertyTypes: 'Property Types',

    spaces: 'spaces',
    elevators: 'Elevators',
    elev: 'elev.',
    floors: 'floors',
    units: 'units',
    sale: 'Sale',
    lease: 'Lease',
    search: 'Search',
    clear: 'Clear',
    advanceSearch: 'Advance Search',
    location: 'Location',
    searchListingsForSale: 'Search Listings for Sale',
    searchListingsForLease: 'Search Listings for Lease',
    searchLocation: 'Search Location',
    price: 'Price',
    priceRange: 'Price Range',
    size: 'Size',

    // Listing Edit Form

    // INFORMATION ABOUT THE LOCATION
    listingInformation: 'Listing Information',
    country: 'Country',
    municipality: 'Municipality',
    zipCode: 'Zip Code',
    propertyAddress: 'Property Address',
    taxIdNumber: 'Tax Id Number',
    coordinates: 'Coordinates',
    latitude: 'Latitude',
    longitude: 'Longitude',
    useMyLocation: 'Use my location',
    zoning: 'Zoning',
    femaMap: 'FEMA Map',

    // INFORMATION ABOUT THE PROPERTY
    saleType: 'Sale Type',
    yearBuilt: 'Year Built',
    propertyType: 'Property Type',
    buildingClass: 'Building Class',
    buildingSize: 'Building Size',
    buildingSizeSqFt: 'Building Size (square feet)',
    lotSize: 'Lot Size',
    lotSizeSqMt: 'Lot Size (square meeters)',
    parkingSpaces: 'Parking Spaces',
    ceilingHeight: 'Ceiling Height',
    ceilingHeightFt: 'Ceiling Height (in feet)',
    mezzanine: 'Mezzanine',
    hasMezzanine: 'Has Mezzanine?',
    mezzanineSize: 'Mezzanine Size',
    mezzanineSizeSqFt: 'Size in square feet',
    numberOfLoadingDocks: 'Number of Loading Docks',
    numberOfColumns: 'Number of Columns',
    officePercent: 'Office Percent',
    numberOfBuildings: 'Number of Buildings',
    numberOfUnits: 'Number of Units',
    numberOfFloors: 'Number of Floors',
    numberOfElectricMeters: 'Number of Electric Meters',
    numberOfWaterMeters: 'Number of Water Meters',
    photos: 'Photos',

    // FINANCIAL INFORMATION
    financialInformation: 'Financial Information',
    askingPrice: 'Asking Price',
    annualPropertyIncome: 'Annual Property Income',
    yearlyPropertyExpenses: 'Yearly Property Expenses',

    // METRICS

    metrics: 'Metrics',
    pricePerSquareFootage: 'Price per Square Foot',
    pricePerSquareMeter: 'Price per Square Meter',
    pricePerUnit: 'Price per Unit',
    incomePerSquareFootage: 'Income per Square Foot',
    incomePerSquareMeter: 'Income per Square Meter',
    incomePerUnit: 'Income per Unit',
    expensesPerSquareFootage: 'Expenses per Square Foot',
    expensesPerSquareMeters: 'Expenses per Square Meter',
    expensePerUnit: 'Expenses per Unit',
    propertyNoi: 'Property NOI',
    noiPerSquareFeet: 'NOI per Square Feet',
    noiPerSquareMeter: 'NOI per Square Meter',
    noiPerUnit: 'NOI per Unit',
    operatingExpenseRatio: 'Operating Expense Ratio',
    noiProfitMarginRatio: 'NOI Profit Margin Ratio',
    paybackPeriod: 'Payback Period',
    parkingRatio: 'Parking Ratio',

    // INFORMATION ABOUT THE USER

    userName: 'User Name',
    userType: 'User Type',
    realEstateLicenseNumber: 'Real Estate License Number',
    realEstateCompanyName: 'Real Estate Company Name',
    realEstateCompanyLogo: 'Real Estate Company Logo',
    realEstateAgentPhoto: 'Real Estate Agent Photo',
    userTelephoneNumber: 'User Telephone Number',
    userEmailAddress: 'User Email Address',

    // AMENITIES
    amenities: 'Amenities',
    sprinklers: 'Sprinklers',
    elevator: 'Elevator',
    security: 'Security',
    powerPlant: 'Power Plant',
    waterTank: 'Water Tank',
    loadingDocks: 'Loading Docks',
    driveThru: 'Drive-Thru',
    centralAirConditioner: 'Central Air Conditioner',
    electricMeters: 'Electric Meters',
    waterMeters: 'Water Meters',
    airConditionerOnNightsAndWeekends: 'Air Conditioners on Nights and Weekends',

    // Listing Photos
    dropListingPhotos: 'Drag and drop some files here, or click to select files',
    loadingImages: 'Loading Images ...',
    imageTotal: 'Image total: ',
    imageName: 'Image name',

    // Points of interest

    poi: 'POI',
    pointsOfInterest: 'Points Of Interest',
    newPointsOfInterest: 'New Point Of Interest',
    timeToPointOfInterest: 'Time to point of interest',
    timeToPointOfInterestMinutes: 'Time in minutes to point of interest',

    // Password Manager ===================================
    passwords: 'Passwords',
    passwordManager: 'Password Manager',
    generatePassword: 'Generate Password',
    passwordLength: 'Password Length',
    newPassword: 'New Password',
    passwordSaved: 'Password Saved!',
    passwordUpdated: 'Password updated!',
    searchPassword: 'Password search',
    passwordDeleted: 'Password deleted!',

    // TO-DO ===================================
    welcomeToTodoCardsTitle: 'Welcome to Todo Cards',
    welcomeToTodoCardsDesc: "Let's start by adding your first card.",
    todoCards: 'Todo Cards',
    todoCard: 'Todo Card',

    newTodoCard: 'New Card',
    todoCardDeleted: 'Todo card deleted',

    todoItemSaved: 'Todo Item Edit',
    todoItemEdit: 'Todo Item Edit',

    shareTodo: 'Share Todo',
    todoShared: 'Todo Shared!',

    // NOTES ===================================

    allNotes: 'All Notes',
    notes: 'Notes',
    note: 'Note',
    newNote: 'New Note',
    notebooks: 'Notebooks',
    notebook: 'Notebook',
    newNotebook: 'New Notebook',
    defaultNotebook: 'Default Notebook',
    groupWith: 'Group width',
    deleteNotebookConfirmationMessageTitle: 'Are you sure you want to delete notebook?',
    deleteNotebookConfirmationMessageBody: "Deleting the notebook will delete all it's notes.",
    noteCreated: 'Note created!',
    noteDeleted: 'Note deleted!',
    noteSaved: 'Note saved!',
    welcomeToNotesTitle: 'Welcome to Dakity Notes.',
    welcomeToNotesBody: "Let's start by adding your first notebook.",
    noNotesTitle: 'An exiting note is waiting to be listed here.',
    shareNotebook: 'Share Notebook',
    notebookShared: 'Notebook Shared!',
    sharedNotebooks: 'Shared Notebooks',
    myNotebooks: 'My Notebooks',
    notebookSharedPreNotificationInstructions1: 'Hello #toName, ',
    notebookSharedPreNotificationInstructions2:
      '#fromName has invited you to collaborate with the #notificationType titled `#contentName`.',
    notebookSharedPreNotificationInstructions3: 'Please follow link below if you with to continue.',
    noteUnableToDetermineDefaultNotebook: 'Unable to determine a default notebook',

    // BEERS ===================================
    beers: 'Beers',
    welcomeToTheBeerCoolerTitle: 'Welcome to the Beer Cooler',
    welcomeToTheBeerCoolerDesc: "Let's start by adding your first beer.",
    newBeer: 'New Beer',
    searchBeer: 'Search Beers',
    dropBeerPhoto: 'Drag and drop to add or update beer photo, or click to select file',
    beerAddedToCooler: 'Beer added to cooler!',
    beerUpdated: 'Beer updated!',
    brewery: 'Brewery',
    beerStyle: 'Beer Style',
    deleteBeerConfirmation: 'Wanna delete this beer?',
    addNewBrewery: 'Add new brewery',
    addNewBreweryContextMessage: 'Did you miss any brewery in our list? Please, add it!',
    addNewBeerStyle: 'Add new beer style',
    addNewBeerStyleContextMessage: 'Did you miss any beer style in our list? Please, add it!',
    totalBeersInCooler: 'total beers in cooler',

    // WINES ===================================
    wines: 'Wines',
    welcomeToTheWineCellarTitle: 'Welcome to the wine cellar.',
    welcomeToTheWineCellarDesc: "Let's start by adding your first wine.",
    newWine: 'New Wine',
    searchWine: 'Search Wine Cellar',
    dropWinePhoto: 'Drag and drop to add or update wine photo, or click to select file',
    wineAddedToCellar: 'Wine added to cellar!',
    wineUpdated: 'Wine updated!',
    grape: 'Grape',
    wineStyle: 'Wine Style',
    deleteWineConfirmation: 'Delete this wine?',
    wineDeleted: 'Wine removed from cellar.',
    addNewGrape: 'Add new grape',
    addNewGrapeContextMessage: 'Did you miss any grape in our list? Please, add it!',
    addNewWineStyle: 'Add new wine style',
    addNewWineStyleContextMessage: 'Did you miss any wine style in our list? Please, add it!',
    totalWinesInCellar: 'total wines in cellar',

    invalidABVValue: 'Invalid value. Please use an integer or decimal value only e.g. (7.2)',
    rating: 'Rating',
    drinkPlace: 'Drink Place',
    alcoholByVolume: 'Alcohol by Volume',
    abvPercent: 'ABV %',
    tastingNote: 'Tasting Note',
    sortBy: 'Sort By',
    style: 'Style',
    added: 'Added',
    year: 'Year',

    // VEHICLES ===================================
    vehicles: 'Vehicles',
    vehicleManager: 'Vehicle Manager',
    vin: 'VIN',
    welcomeToTheVehicleGarageTitle: 'Welcome to the Vehicle Garage',
    welcomeToTheVehicleGarageDesc: "Let's start by adding your first vehicle.",
    deleteVehicleConfirmation: "Are you sure you want to delete this vehicle?",
    make: "Make",
    model: "Model",
    year: "Year",
    color: "Color",
    mileage: "Mileage",
    VehicleAddedToGarage: "Vehicle added to garage!",
    VehicleUpdated: "Vehicle washed, polished and updated!",



  },

  /*
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */

  es: {
    dailyTools: 'Daily Tools',

    // ==================================
    // Common
    changeLanguage: 'Change Language',
    dashboard: 'Dashboard',
    notFound: 'Not Found',
    myProfile: 'My Profile',
    profile: 'Profile',
    register: 'Register',
    signIn: 'Sign In',
    signOut: 'Sign Out',
    logIn: 'Log In',
    logOut: 'Log Out',
    contact: 'Contact',
    about: 'About',
    address: 'Address',

    general: 'General',
    description: 'Description',
    home: 'Home',
    save: 'Save',
    add: 'Add',
    cancel: 'Cancel',
    total: 'Total',
    from: 'From',
    to: 'To',
    all: 'All',
    category: 'Category',
    icon: 'Icon',
    building: 'Building',
    bus: 'Bus',
    plane: 'Plane',
    restaurants: 'Restaurants',
    min: 'min',
    new: 'New',
    tools: 'Tools',
    showing: 'Showing',
    of: 'of',

    lastUpdated: 'last updated ',
    uLastUpdated: 'Last updated ',
    updated: 'updated!',
    saved: 'saved!',
    deleted: 'deleted!',

    dueDate: 'Due Date',
    addDueDate: 'Add Due Date',
    today: 'Today',
    tomorrow: 'Tomorrow',
    nextWeek: 'Next Week',
    pickADate: 'Pick a Date',
    notifyMe: 'Notify Me',

    default: 'Default',
    isDefault: 'Is default',
    setAsDefault: 'Set as default',
    actions: 'Actions',
    whatIsOnYourMind: 'What is on your mind ...',
    unexpectedErrorOcurred: 'Unexpected application error has occurred',
    by: 'by',
    loadMore: 'Load More',
    deleteConfirmationMessage: 'Are you sure you want to delete ',
    comment: 'Comment',

    continue: 'continue',
    moved: 'moved',
    
    // Navigation
    siteSearch: 'Site Search',
    administration: 'Administration',

    // Common.Validation.Messages
    validationFieldRequired: 'Field required',

    // Notifications
    notifications: 'Notifications',
    notificationConfirmationLoadingError: 'Unable to load content. Sorry for the inconvenience.',

    // Share Content
    share: 'Share',
    shared: 'Shared',
    sharedWithOthers: 'Shared with others.',
    sharedWithMe: 'Shared with me.',
    sharedSuccessMessage: 'Content shared!',
    shareInstructions: 'Fill out the information below to share your content.',
    accessLevel: 'Access Level',

    // Profile
    email: 'Email',
    name: 'Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    phone: 'Phone',
    title: 'Title',
    aboutMe: 'About Me',
    language: 'Language',
    dropProfilePhoto: 'Drag and drop to update profile photo, or click to select file',
    profilePhotoUpdated: 'Profile photo updated!',

    // Units
    mile: 'Mile',
    miles: 'Miles',
    kilometer: 'Kilometer',
    kilometers: 'Kilometers',
    meter: 'Meter',
    meters: 'Meters',
    feet: 'Feet',
    sqFt: 'sqFt',
    sqMt: 'sqMt',
    squareFeet: 'Square Feet',

    firstHad: 'First Had',

    // Security
    youHaveBeenLoggedOutDueToInactivity: 'You have been logged out due to inactivity.',
    account: 'Account',
    createAccount: 'Create Account',
    creatingAccount: 'Creating Account ...',
    accountCreated: 'Account Created!',
    accountValidateCode: 'Account Activation',
    accountActivationCompleted: 'Congratulations, Account Activation Completed!',
    accountActivationCode: 'Account Activation Code',
    activationCode: 'Activation Code',
    requestNewCode: 'Request New Code',
    newTemporaryCodeSent: 'New code has been sent.',
    createPassword: 'Create Password',
    passwordRules: 'Password Guidelines',
    passwordDoNotMatch: 'Confirmation Password does not match',
    welcomeToDakityInstructions: 'Welcome to Dakity. Please enter the code sent to your email in the input box below.',
    registration: 'Registration',
    login: 'Login',
    accountLogin: 'Account Login',
    accountSettings: 'Account Settings',
    accountClickRegister: 'Do not have an account yet? Click to register.',
    password: 'Password',
    youForgotPasswordTitle: 'You forgot your password?',
    youForgotPasswordInstructions:
      'We would be happy to help you. \nPlease enter the information below so you can reset the password for your account.',
    confirmPassword: 'Confirm Password',
    recoverPassword: 'Recover Password',
    recoverPasswordEmailInstructions:
      'A validation code has been sent to your email. Please check your email to complete recovery process.',
    totalPasswords: 'Total Passwords',
    forgotPassword: 'Forgot Password?',
    sessionEndedRedirecting: 'Session ended, redirecting to home page...',
    accessingSystem: 'Loading session...',
    requestPasswordReset: 'Request Password Reset',
    emailValidation: 'Email Validation',
    validationRequiredEmail: 'Email required!',
    validationRequiredPassword: 'Password Required',
    validationCustomEmailInvalid: 'Invalid email address',
    validationMinLengthPassword: 'Minimum password length is {0}',
    usernameCopiedToClipboard: 'Username copied to clipboard',
    passwordCopiedToClipboard: 'Password copied to clipboard',

    unableToValidateUser: 'Unable to validate user.',

    learnMoreAboutHowItWorks: 'Learn more about how LISTATEC works',
    howListatecWorks: 'How LISTATEC Works?',
    howListatecWorksSub: 'Search for types of commercial property and location.',
    refineSearch: 'Refine Search',
    refineSearchSub: 'Look for different ways to enhanced your search.',
    compareOrContact: 'Add Compare Contact',
    compareOrContactSub: 'Add them to your list, compare them for better analysis or contact agent.',
    searchCompareContact: 'Search Compare Contact',
    noListingsFound: 'No listings found.',

    saveSearch: 'Save Search',
    map: 'Map',
    list: 'List',
    goBackToListings: 'Go back to listings',
    yearly: 'Yearly',
    monthly: 'Monthly',
    images: 'Images',
    details: 'Details',
    information: 'Information',
    nearbyTransportation: 'Nearby/Transportation',
    brokerAgent: 'Broker Agent',
    brokerAgents: 'Broker Agents',

    noDataFound: 'No data found.',
    facilities: 'Facilities',
    drive: 'Drive',
    distance: 'Distance',
    distanceUnit: 'Distance Unit',
    latestCommercialListings: 'Latest Commercial Listings',
    expand: 'Expand',
    close: 'Close',
    compare: 'Compare',
    moreInfo: 'More Information',
    favorites: 'Favorites',
    dashboardConfiguration: 'Dashboard Configuration',
    edit: 'Edit',
    delete: 'Delete',
    connections: 'Connections',
    quickActions: 'Quick Actions',
    leads: 'Leads',
    additionalCriteria: 'Additional Criteria',

    addListing: 'Add Listing',
    listingSearch: 'Listing Search',
    listings: 'Listings',

    connect: 'Connect',
    reports: 'Reports',

    listingProfile: 'Listing',
    example: 'Example: ',

    // Listings  ===================================

    // = Dashboard
    listingDashboard: 'Listing Dashboard',
    trendingListings: 'Trending Listings',

    // Listing Search / Summary
    propertyTypes: 'Property Types',

    spaces: 'spaces',
    elevators: 'Elevators',
    elev: 'elev.',
    floors: 'floors',
    units: 'units',
    sale: 'Sale',
    lease: 'Lease',
    search: 'Search',
    clear: 'Clear',
    advanceSearch: 'Advance Search',
    location: 'Location',
    searchListingsForSale: 'Search Listings for Sale',
    searchListingsForLease: 'Search Listings for Lease',
    searchLocation: 'Search Location',
    price: 'Price',
    priceRange: 'Price Range',
    size: 'Size',

    // Listing Edit Form

    // INFORMATION ABOUT THE LOCATION
    listingInformation: 'Listing Information',
    country: 'Country',
    municipality: 'Municipality',
    zipCode: 'Zip Code',
    propertyAddress: 'Property Address',
    taxIdNumber: 'Tax Id Number',
    coordinates: 'Coordinates',
    latitude: 'Latitude',
    longitude: 'Longitude',
    useMyLocation: 'Use my location',
    zoning: 'Zoning',
    femaMap: 'FEMA Map',

    // INFORMATION ABOUT THE PROPERTY
    saleType: 'Sale Type',
    yearBuilt: 'Year Built',
    propertyType: 'Property Type',
    buildingClass: 'Building Class',
    buildingSize: 'Building Size',
    buildingSizeSqFt: 'Building Size (square feet)',
    lotSize: 'Lot Size',
    lotSizeSqMt: 'Lot Size (square meeters)',
    parkingSpaces: 'Parking Spaces',
    ceilingHeight: 'Ceiling Height',
    ceilingHeightFt: 'Ceiling Height (in feet)',
    mezzanine: 'Mezzanine',
    hasMezzanine: 'Has Mezzanine?',
    mezzanineSize: 'Mezzanine Size',
    mezzanineSizeSqFt: 'Size in square feet',
    numberOfLoadingDocks: 'Number of Loading Docks',
    numberOfColumns: 'Number of Columns',
    officePercent: 'Office Percent',
    numberOfBuildings: 'Number of Buildings',
    numberOfUnits: 'Number of Units',
    numberOfFloors: 'Number of Floors',
    numberOfElectricMeters: 'Number of Electric Meters',
    numberOfWaterMeters: 'Number of Water Meters',
    photos: 'Photos',

    // FINANCIAL INFORMATION
    financialInformation: 'Financial Information',
    askingPrice: 'Asking Price',
    annualPropertyIncome: 'Annual Property Income',
    yearlyPropertyExpenses: 'Yearly Property Expenses',

    // METRICS

    metrics: 'Metrics',
    pricePerSquareFootage: 'Price per Square Foot',
    pricePerSquareMeter: 'Price per Square Meter',
    pricePerUnit: 'Price per Unit',
    incomePerSquareFootage: 'Income per Square Foot',
    incomePerSquareMeter: 'Income per Square Meter',
    incomePerUnit: 'Income per Unit',
    expensesPerSquareFootage: 'Expenses per Square Foot',
    expensesPerSquareMeters: 'Expenses per Square Meter',
    expensePerUnit: 'Expenses per Unit',
    propertyNoi: 'Property NOI',
    noiPerSquareFeet: 'NOI per Square Feet',
    noiPerSquareMeter: 'NOI per Square Meter',
    noiPerUnit: 'NOI per Unit',
    operatingExpenseRatio: 'Operating Expense Ratio',
    noiProfitMarginRatio: 'NOI Profit Margin Ratio',
    paybackPeriod: 'Payback Period',
    parkingRatio: 'Parking Ratio',

    // INFORMATION ABOUT THE USER

    userName: 'User Name',
    userType: 'User Type',
    realEstateLicenseNumber: 'Real Estate License Number',
    realEstateCompanyName: 'Real Estate Company Name',
    realEstateCompanyLogo: 'Real Estate Company Logo',
    realEstateAgentPhoto: 'Real Estate Agent Photo',
    userTelephoneNumber: 'User Telephone Number',
    userEmailAddress: 'User Email Address',

    // AMENITIES
    amenities: 'Amenities',
    sprinklers: 'Sprinklers',
    elevator: 'Elevator',
    security: 'Security',
    powerPlant: 'Power Plant',
    waterTank: 'Water Tank',
    loadingDocks: 'Loading Docks',
    driveThru: 'Drive-Thru',
    centralAirConditioner: 'Central Air Conditioner',
    electricMeters: 'Electric Meters',
    waterMeters: 'Water Meters',
    airConditionerOnNightsAndWeekends: 'Air Conditioners on Nights and Weekends',

    // Listing Photos
    dropListingPhotos: 'Drag and drop some files here, or click to select files',
    loadingImages: 'Loading Images ...',
    imageTotal: 'Image total: ',
    imageName: 'Image name',

    // Points of interest

    poi: 'POI',
    pointsOfInterest: 'Points Of Interest',
    newPointsOfInterest: 'New Point Of Interest',
    timeToPointOfInterest: 'Time to point of interest',
    timeToPointOfInterestMinutes: 'Time in minutes to point of interest',

    // Password Manager ===================================
    passwords: 'Passwords',
    passwordManager: 'Password Manager',
    generatePassword: 'Generate Password',
    passwordLength: 'Password Length',
    newPassword: 'New Password',
    passwordSaved: 'Password Saved!',
    passwordUpdated: 'Password updated!',
    searchPassword: 'Password search',
    passwordDeleted: 'Password deleted!',

    // TO-DO ===================================
    welcomeToTodoCardsTitle: 'Welcome to Todo Cards',
    welcomeToTodoCardsDesc: "Let's start by adding your first card.",
    todoCards: 'Todo Cards',
    todoCard: 'Todo Card',

    newTodoCard: 'New Card',
    todoCardDeleted: 'Todo card deleted',

    todoItemSaved: 'Todo Item Edit',
    todoItemEdit: 'Todo Item Edit',

    shareTodo: 'Share Todo',
    todoShared: 'Todo Shared!',

    // NOTES ===================================

    allNotes: 'All Notes',
    notes: 'Notes',
    note: 'Note',
    newNote: 'New Note',
    notebooks: 'Notebooks',
    notebook: 'Notebook',
    newNotebook: 'New Notebook',
    defaultNotebook: 'Default Notebook',
    groupWith: 'Group width',
    deleteNotebookConfirmationMessageTitle: 'Are you sure you want to delete notebook?',
    deleteNotebookConfirmationMessageBody: "Deleting the notebook will delete all it's notes.",
    noteCreated: 'Note created!',
    noteDeleted: 'Note deleted!',
    noteSaved: 'Note saved!',
    welcomeToNotesTitle: 'Welcome to Dakity Notes.',
    welcomeToNotesBody: "Let's start by adding your first notebook.",
    noNotesTitle: 'An exiting note is waiting to be listed here.',
    shareNotebook: 'Share Notebook',
    notebookShared: 'Notebook Shared!',
    sharedNotebooks: 'Shared Notebooks',
    myNotebooks: 'My Notebooks',
    notebookSharedPreNotificationInstructions1: 'Hello #toName, ',
    notebookSharedPreNotificationInstructions2:
      '#fromName has invited you to collaborate with the #notificationType titled `#contentName`.',
    notebookSharedPreNotificationInstructions3: 'Please follow link below if you with to continue.',
    noteUnableToDetermineDefaultNotebook: 'Unable to determine a default notebook',

    // BEERS ===================================
    beers: 'Beers',
    welcomeToTheBeerCoolerTitle: 'Welcome to the Beer Cooler',
    welcomeToTheBeerCoolerDesc: "Let's start by adding your first beer.",
    newBeer: 'New Beer',
    searchBeer: 'Search Beers',
    dropBeerPhoto: 'Drag and drop to add or update beer photo, or click to select file',
    beerAddedToCooler: 'Beer added to cooler!',
    beerUpdated: 'Beer updated!',
    brewery: 'Brewery',
    beerStyle: 'Beer Style',
    deleteBeerConfirmation: 'Wanna delete this beer?',
    addNewBrewery: 'Add new brewery',
    addNewBreweryContextMessage: 'Did you miss any brewery in our list? Please, add it!',
    addNewBeerStyle: 'Add new beer style',
    addNewBeerStyleContextMessage: 'Did you miss any beer style in our list? Please, add it!',
    totalBeersInCooler: 'total beers in cooler',

    // WINES ===================================
    wines: 'Wines',
    welcomeToTheWineCellarTitle: 'Welcome to the wine cellar.',
    welcomeToTheWineCellarDesc: "Let's start by adding your first wine.",
    newWine: 'New Wine',
    searchWine: 'Search Wine Cellar',
    dropWinePhoto: 'Drag and drop to add or update wine photo, or click to select file',
    wineAddedToCellar: 'Wine added to cellar!',
    wineUpdated: 'Wine updated!',
    grape: 'Grape',
    wineStyle: 'Wine Style',
    deleteWineConfirmation: 'Delete this wine?',
    wineDeleted: 'Wine removed from cellar.',
    addNewGrape: 'Add new grape',
    addNewGrapeContextMessage: 'Did you miss any grape in our list? Please, add it!',
    addNewWineStyle: 'Add new wine style',
    addNewWineStyleContextMessage: 'Did you miss any wine style in our list? Please, add it!',
totalWinesInCellar: 'total wines in cellar',

    invalidABVValue: 'Invalid value. Please use an integer or decimal value only e.g. (7.2)',
    rating: 'Rating',
    drinkPlace: 'Drink Place',
    alcoholByVolume: 'Alcohol by Volume',
    abvPercent: 'ABV %',
    tastingNote: 'Tasting Note',
    sortBy: 'Sort By',
    style: 'Style',
    added: 'Added',
    year: 'Year',


    
    // VEHICLES ===================================
    vehicles: 'Vehicles',
    vehicleManager: 'Vehicle Manager',
    vin: 'VIN',
    welcomeToTheVehicleGarageTitle: 'Welcome to the Vehicle Garage',
    welcomeToTheVehicleGarageDesc: "Let's start by adding your first vehicle.",
    deleteVehicleConfirmation: "Are you sure you want to delete this vehicle?",
    make: "Make",
    model: "Model",
    year: "Year",
    color: "Color",
    mileage: "Mileage",
  },

  /*
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */
};
export { data };
