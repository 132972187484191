import React, { useState, useEffect } from 'react';
import './App.scss';

import PropTypes from 'prop-types';

// Redux
import { useSelector } from 'react-redux';

// Routing
import { Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from '../core/routes/PrivateRoute';


// State
import { connect } from 'react-redux';
import { changeLanguage } from './redux/app/app.actions';
import { setWidth, setHeight } from './redux/responsive/responsive.actions';

// Utils
import responsive from '../core/responsive';

// Auth
import AccountRegister from './features/account/register/AccountRegister';
import AccountValidateCode from './features/account/register/AccountValidateCode';
import AccountPasswordRecover from './features/account/recover/AccountPasswordRecover';
import AccountEmail from './features/account/recover/AccountEmail';
import Login from '../components/features/account/login/Login';
import Logout from '../components/features/account/login/Logout';

// Core Components
import { Loader } from '../core/components';
import { Header } from './layout/header/header';
import SideNavigation from './layout/sideNav/sideNavigation';
import Footer from './layout/footer/footer';
import NotFound from './features/notFound/notFound';
import Error from './features/error/error.page';
import Diagnostics from './features/Diagnostics/Diagnostics';

// Feature Components
import Dashboard from './features/dashboard/dashboard';
import AccountSettings from './features/account/settings/accountSettings';
import PasswordManager from './features/passwordManager/passwordManager';
import TodoSearch from './features/todo/todoSearch';
import NoteContainer from './features/notes/note/NoteContainer';
import NotebookContainer from './features/notes/notebook/NotebookContainer';
import BeerContainer from './features/beer/BeerContainer';
import BeerEdit from './features/beer/BeerEdit';
import WineContainer from './features/wine/WineContainer';
import WineEdit from './features/wine/WineEdit';
import PreNotification from './features/notification/PreNotification';
import Notification from './features/notification/Notification';

// Listings
import ListingDashboard from './features/listings/ListingDashboard';
// import AdminListingSearch from './features/listings/search/ListingSearch';
import ListingEdit from './features/listings/edit/ListingEdit';
import AppInactivityLogout from './AppInactivityLogout';

// Vehicle
import VehicleContainer from './features/vehicle/VehicleContainer';
import VehicleEdit from './features/vehicle/VehicleEdit';


const App = ({ lang, breakPoint, setWidth }) => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState(null);
  const [isSideNavOpen, setIsSideNavOpen] = React.useState();
  const user = useSelector((state) => state.auth.user);


  useEffect(() => {
    var size = {
      width: window.innerWidth || document.body.clientWidth,
      height: window.innerHeight || document.body.clientHeight,
    };

    // Set responsive width.
    setWidth(size.width);

    if (size.width < responsive.smMinWidth) {
      if (currentBreakPoint !== 'xSmall') {
        setCurrentBreakPoint('xSmall');
      }
    }

    if (size.width > responsive.smMinWidth && size.width < responsive.mdMinWidth) {
      if (currentBreakPoint !== 'small') {
        setCurrentBreakPoint('Small');
      }
    }

    if (size.width > responsive.mdMinWidth && size.width < responsive.lgMinWidth) {
      if (currentBreakPoint !== 'medium') {
        setCurrentBreakPoint('medium');
      }
    }

    if (size.width >= responsive.lgMinWidth) {
      if (currentBreakPoint !== 'large') {
        setCurrentBreakPoint('large');
      }
    }

  }, [setWidth,currentBreakPoint]);

  useEffect(() => {

    if (window.newrelic && user) {
      window.newrelic.setCustomAttribute('userId', user.profile.name);
      // window.newrelic.addPageAction('pageLoad', { page: 'home' });
    }
  }, [user]);

  const onSideNavToggle = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  return (
    <div id="cx-wrapper">
      <div id="cx-app" className={isSideNavOpen ? 'cx cx-sidebar-open' : 'cx'}>
        {currentBreakPoint === null ? (
          <Loader />
        ) : (
          <>
            <SideNavigation lang={lang} breakPoint={currentBreakPoint} onSideNavToggle={onSideNavToggle} />
            <AppInactivityLogout />
            <div className="cx-content-wrapper">
              <Header lang={lang} isLarge={breakPoint === 'large'} onSideNavToggle={onSideNavToggle} />

              <div className="cx-content">
                <Switch>
                  {/* Notifications */}
                  <Route path="/notifications/pre/:id" component={PreNotification} />
                  <PrivateRoute path="/notifications/:id" component={Notification} />

                  {/* Account */}
                  <Route path="/login" component={Login} />
                  <PrivateRoute exact path="/logout" component={withRouter(Logout)} />
                  <Route exact path="/account/register" component={withRouter(AccountRegister)} />
                  <Route exact path="/account/activation" component={withRouter(AccountValidateCode)} />
                  <Route exact path="/account/email" component={withRouter(AccountEmail)} />
                  <Route exact path="/account/password/recover" component={withRouter(AccountPasswordRecover)} />
                  <PrivateRoute exact path="/account/settings" component={withRouter(AccountSettings)} />
                  <PrivateRoute exact path="/dashboard" component={withRouter(Dashboard)} />

                  {/* Listings */}
                  <PrivateRoute exact path="/listings" component={withRouter(ListingDashboard)} />
                  {/* <SecuredRoute exact path="/listings" component={withRouter(AdminListingSearch)} /> */}
                  <PrivateRoute exact path="/listings/:id" component={withRouter(ListingEdit)} />

                  {/* TO-DO */}
                  <PrivateRoute exact path="/todo" component={withRouter(TodoSearch)} />
                  <PrivateRoute exact path="/password-manager" component={withRouter(PasswordManager)} />

                  {/* Notes */}
                  <PrivateRoute exact path="/notes" component={withRouter(NoteContainer)} />
                  <PrivateRoute exact path="/notebooks" component={withRouter(NotebookContainer)} />

                  {/* Beer */}
                  <PrivateRoute exact path="/beers" component={withRouter(BeerContainer)} />
                  <PrivateRoute exact path="/beers/:id" component={withRouter(BeerEdit)} />

                  {/* Wine */}
                  <PrivateRoute exact path="/wines" component={withRouter(WineContainer)} />
                  <PrivateRoute exact path="/wines/:id" component={withRouter(WineEdit)} />

                  {/* Vehicle */}
                  <PrivateRoute exact path="/vehicle-manager" component={withRouter(VehicleContainer)} />
                  <PrivateRoute exact path="/vehicle-manager/:id" component={withRouter(VehicleEdit)} />

                  {/* Defaults */}
                  <PrivateRoute exact path="/" component={withRouter(Dashboard)} />
                  <Route exact path="/not-found" component={NotFound} />
                  <Route exact path="/diagnostics" component={Diagnostics} />
                  <Route exact path="/error" component={Error} />
                </Switch>
              </div>
              <Footer />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

App.propTypes = {
  lang: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props = {}) => {
  return {
    lang: state.app.lang,
    breakPoint: state.responsive.breakPoint,
  };
};

const mapDispatchToProps = {
  changeLanguage,
  setWidth,
  setHeight,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
