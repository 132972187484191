import { SETWORKINGNOTEBOOK } from './notes.actions';

const getInitWorkingNotebook = () => {
  const storedNotebook = localStorage.getItem(SETWORKINGNOTEBOOK);
  if (!storedNotebook) {
    return null;
  }
  return JSON.parse(storedNotebook);
};

const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

const initialState = {
  workingNotebook: getInitWorkingNotebook(),
};

export const notes = (state = initialState, action) => {
  switch (action.type) {
    case SETWORKINGNOTEBOOK:
      const newData = deepCopy(action.data); // Use deep copy function
      localStorage.setItem(SETWORKINGNOTEBOOK, JSON.stringify(newData));
      return {
        ...state,
        workingNotebook: newData,
      };
    default:
      return state;
  }
};