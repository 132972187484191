import React, { useEffect, useState, useRef } from 'react';
import './VehicleContainer.scss';

import useApi from '../../../core/useApi';
import responsive from '../../../core/responsive';
import { Breadcrumb, Loader, EmptyState, BrowserWindowsReachBottomScroll } from '../../../core/components';

// State
import { useSelector } from 'react-redux';

// Mui
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

import { VehicleSearchRequest } from './VehicleSearch.dto';
import VehicleItem from './VehicleItem';
import { useHistory } from 'react-router-dom';

const VehicleContainer = () => {
    const lang = useSelector((state) => state.app.lang);

    const breadcrumb = [
        { name: lang.dashboard, path: '/dashboard' },
        { name: lang.vehicleManager, path: null },
    ];
    
    const history = useHistory();
    const { get, parseQuery } = useApi();
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchRequest, setSearchRequest] = useState(new VehicleSearchRequest());
    const [isSearching, setIsSearching] = useState(false);
    const searchRef = useRef();


    // Responsive ====================================================================
    const [isLarge] = useState(window.innerWidth >= responsive.mdMinWidth);

    const getVehicles = async (search, loadMore) => {
        setIsLoading(true);
        try {

            const queryParams = parseQuery(search);
            const response = await get(`vehicle/search${queryParams}`);

            if (loadMore) {
                var newItems = [...items];
                setItems(newItems.concat(response.items));
                search.showingCount = newItems.length;

            } else {
                setItems(response.items);
                search.showingCount = response.items.length;

            }

            search.count = response.count;
            search.limit = response.limit;
            search.skip = response.skip;
            setSearchRequest(search);
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {
        getVehicles(searchRequest, false);
    }, []);







    const searchClick = () => {
        const request = { ...searchRequest };
        request.search.text = searchRef.current.value;
        request.skip = 0;
        console.log(request);
        getVehicles(request, false);
        setIsSearching(true);
    };
    const clearSearchClick = () => {
        searchRef.current.value = null;
        const request = { ...searchRequest };
        request.search.text = null;
        request.skip = 0;
        getVehicles(request, false);
        setIsSearching(false);
    };

    const handleLoadMore = () => {
        const request = { ...searchRequest };
        request.skip = parseInt(request.skip) + parseInt(request.limit);
        getVehicles(request, true);
    };

    const newVehicleClick = () => {
        history.push(`/vehicle-manager/new`);
    };
    const handleVehicleItemClick = (item) => {
        history.push(`/vehicle-manager/${item.id}`);
    };
 

    // Filter pop over
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleSortClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const onSortClick = (sortField) => {
        const request = { ...searchRequest };
        const { sort } = request.search;

        if (sortField === sort) {
            request.search.sortDirection = request.search.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            request.search.sort = sortField;
            request.search.sortDirection = 'asc';
        }

        request.skip = 0;
        setSearchRequest(request);
        getVehicles(request);
        setAnchorEl(null);
    };










    return (
        <div className="cx-vehicles">
            <Breadcrumb items={breadcrumb} />
            <div className="cx-vehicles-search">
                <Paper component="form" sx={{ p: '0px 8px', display: 'flex', alignItems: 'center' }}>
                    <InputBase
                        type="text"
                        inputRef={searchRef}
                        placeholder={lang.searchVehicle}
                        sx={{ ml: 1, flex: 1 }}
                        inputProps={{ 'aria-label': lang.searchVehicle }}
                    />
                    {isSearching ? (
                        <IconButton type="button" aria-label="clear-search" onClick={clearSearchClick} size="large">
                            <i className="icon-times" />
                        </IconButton>
                    ) : (
                        <IconButton type="button" aria-label="search" onClick={searchClick} size="large">
                            <i className="icon-search" />
                        </IconButton>
                    )}
                    <Divider orientation="vertical" />
                    <IconButton type="button" aria-label="sort" onClick={handleSortClick} size="large">
                        <i className="icon-sort-amount-asc" />
                    </IconButton>
                    <Divider orientation="vertical" />
                    <button type="button" className="cx-button cx-button-new" onClick={newVehicleClick}>
                        {isLarge ? lang.newVehicle : <i className="icon-plus" />}
                    </button>
                </Paper>
            </div>

            {isLoading && items.length === 0 ? (
                <Loader />
            ) : (
                <>
                    {!isSearching && items.length === 0 ? (
                        <EmptyState
                            title={lang.welcomeToTheVehicleGarageTitle}
                            description={lang.welcomeToTheVehicleGarageDesc}
                            onActionClick={newVehicleClick}
                            actionText={lang.newVehicle}
                        />
                    ) : (
                        <>
                            <div className='cx-vehicles-total'>
                                {lang.showing} {searchRequest.showingCount} {lang.of} {searchRequest.count} {lang.totalVehiclesInCooler}
                            </div>
                            <div className="cx-vehicles-list">
                                {items.map((item) => (
                                    <VehicleItem
                                        key={item.id}
                                        lang={lang}
                                        item={item}
                                        onVehicleItemClick={handleVehicleItemClick}
                                    />
                                ))}
                            </div>

                            <div className="cx-vehicles-load-more">
                                <BrowserWindowsReachBottomScroll padding={3} action={handleLoadMore} />
                            </div>
                        </>
                    )}

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className="cx-vehicles-search-sort">
                            <h3>Sort By</h3>
                            <ul>
                                <li onClick={() => onSortClick('vin')}>
                                    Name
                                    {searchRequest.search.sort === 'vin' ? (
                                        searchRequest.search.sortDirection === 'asc' ? (
                                            <i className="icon-sort-amount-asc" />
                                        ) : (
                                            <i className="icon-sort-amount-desc" />
                                        )
                                    ) : null}
                                </li>
                                <li onClick={() => onSortClick('createDate')}>
                                    Added
                                    {searchRequest.search.sort === 'createDate' ? (
                                        searchRequest.search.sortDirection === 'asc' ? (
                                            <i className="icon-sort-amount-asc" />
                                        ) : (
                                            <i className="icon-sort-amount-desc" />
                                        )
                                    ) : null}
                                </li>
                            </ul>
                        </div>
                    </Popover>
                </>
            )}
        </div>
    );
};

export default VehicleContainer;