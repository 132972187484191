export class VehicleSearchDto {
    text = null;
    sort = 'createDate';
    sortDirection = 'desc';
  }
  
  export class VehicleSearchRequest {
    limit = 30;
    skip = 0;
    count = 0;
    showingCount = 0;
    search = new VehicleSearchDto();
  }
  