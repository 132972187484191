import React, { useEffect, useState } from 'react';
import './VehicleEdit.scss';

import useApi from '../../../core/useApi';
import { Breadcrumb, Loader, NoImagePlaceholder, CxSelect } from '../../../core/components';

import { withRouter } from 'react-router-dom';

// State
import { useSelector } from 'react-redux';


// Forms
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Dropzone from 'react-dropzone';

// Mui
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import DialogActions from '@mui/material/DialogActions';

import { helpers } from '../../../core/helpers';



const VehicleEdit = ({ history, match }) => {
    const { get, postWithUpload, putWithUpload, del, upload } = useApi();
    const lang = useSelector((state) => state.app.lang);
    const [isLoading, setIsLoading] = useState(false);
    const [vehicleId] = useState(match.params.id);
    const { enqueueSnackbar } = useSnackbar();

    // Forms
    const schema = yup
        .object({
            vin: yup.string().required(lang.validationFieldRequired).min(17).max(17),
            make: yup.string().required(lang.validationFieldRequired).min(4).max(128),
            model: yup.string().required(lang.validationFieldRequired).min(2).max(128),
            //  year: yup.number().required(lang.validationFieldRequired).max(4),
            mileage: yup.number(),
            color: yup.string().max(128),
        })
        .required();


    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            id: '',
            vin: '',
            make: '',
            model: '',
            year: '',
            color: '',
            mileage: '',
        },
    });

    const breadcrumb = [
        { name: lang.dashboard, path: '/dashboard' },
        { name: lang.vehicles, path: '/vehicle-manager' },
        { name: lang.edit, path: null },
    ];

    // Year
    const [selectedYear, setSelectedYear] = useState('');
    const years = helpers.generateArrayOfYears().map((year) => ({ name: year, value: year }));
    const handleYearChange = (value) => {
        setSelectedYear(value);
        setValue('year', value, true);
    };

    const getVehicle = async () => {
        const response = await get(`vehicle/${vehicleId}`);

        reset({
            id: response.id,
            vin: response.vin,
            make: response.make,
            vehicleModel: response.vehicleModel,
            year: response.year,
            color: response.color,
            mileage: response.mileage,
        });

        setImg(response.imageUrl);

    };

    useEffect(() => {
        setIsLoading(true);
        try {
            if (vehicleId !== 'new') {
                getVehicle();
            }
        } finally {
            setIsLoading(false);
        }
    }, [vehicleId]);

    const onCancelClick = () => {
        history.push('/vehicle-manager');
    };

    // Delete Vehicle
    const [openDeleteDialog, toggleOpenDeleteDialog] = React.useState(false);

    const handleDeleteDialogClose = () => {
        toggleOpenDeleteDialog(false);
    };

    const onDeleteConfirmClick = () => {
        toggleOpenDeleteDialog(true);
    };
    const onDeleteClick = async () => {
        await del(`vehicle/${vehicleId}`);
        toggleOpenDeleteDialog(false);
        enqueueSnackbar('Vehicle deleted!', {
            variant: 'success',
        });
        history.push('/vehicle-manager');
    };

    // Save
    const onSaveClick = async (data, e) => {
        e.preventDefault();
        try {

            console.log('Data:', data);

            const entity = new FormData();

            if (tempImgData) {
                entity.append('files', tempImgData);
            }

            entity.append('vin', data.vin);
            entity.append('make', data.make);
            entity.append('vehicleModel', data.model);
            entity.append('year', data.year);
            entity.append('color', data.color);
            entity.append('mileage', data.mileage);



            const isNew = data.id === '';
            if (isNew) {
                await postWithUpload('vehicle', entity);
                enqueueSnackbar(lang.VehicleAddedToGarage, {
                    variant: 'success',
                });
                history.push('/vehicle-manager');
            } else {
                await putWithUpload(`vehicle/${data.id}`, entity);
                enqueueSnackbar(lang.VehicleUpdated, {
                    variant: 'success',
                });
            }

        } catch (error) {
            console.log(error);
        }
    };

    // Image Upload
    const [tempImgData, setImgData] = useState();
    const [tempImg, setTempImg] = useState();
    const [img, setImg] = useState();
    const myUploader = async (files) => {
        setImgData(files[0]);

        // Temp Upload
        const data = new FormData();
        data.append('file', files[0]);
        const response = await upload(`core/temp-image-upload?width=350&height=350`, data);
        setTempImg(response);
    };

    return (
        <>
            <Breadcrumb items={breadcrumb} />
            <div className="cx-vehicles-edit">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div>
                            <form className="cx-vehicles-edit-form" onSubmit={handleSubmit(onSaveClick)}>
                                <input type="hidden" {...register('id')} />

                                <div className="cx-vehicles-edit-img">
                                    {tempImg ? (
                                        <img src={`data:image/jpg;charset=utf-8;base64,${tempImg}`} alt="Vehicle" />
                                    ) : img ? (
                                        <img src={`${img}&s=450`} alt="Vehicle" />
                                    ) : null}
                                    <div className="upload-actions">
                                        <Dropzone onDrop={myUploader}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps({ multiple: false })}>
                                                        <input {...getInputProps()} />
                                                        {img || tempImg ? null : <NoImagePlaceholder multi={false} />}
                                                        <p>{lang.dropVehiclePhoto}</p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                </div>

                                <div className="cx-vehicles-edit-fields">
                                    <div>
                                        <Controller
                                            name="vin"
                                            control={control}
                                            render={({ field }) =>
                                                <TextField
                                                    {...field}
                                                    label={lang.vin}
                                                    variant="outlined"
                                                    fullWidth
                                                    error={!!errors.vin}
                                                    helperText={errors.vin ? errors.vin.message : ''} />
                                            }
                                        />

                                    </div>

                                    <div>
                                        <CxSelect
                                            name="year"
                                            label={lang.year}
                                            fullWidth
                                            items={years}
                                            defaultValue={selectedYear}
                                            onChange={handleYearChange}
                                        />
                                        {errors.year ? <span className="input-error">{errors.year.message}</span> : ''}

                                    </div>

                                    <div>
                                        <Controller
                                            name="make"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    id="make"
                                                    type="text"
                                                    label={lang.make}
                                                    variant="outlined"
                                                    fullWidth
                                                    {...field}
                                                    error={!!errors.make}
                                                    helperText={errors.make ? errors.make.message : ''}
                                                />
                                            )}
                                        />

                                    </div>

                                    <div>
                                        <Controller
                                            name="model"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    id="model"
                                                    type="text"
                                                    label={lang.model}
                                                    variant="outlined"
                                                    fullWidth
                                                    {...field} // Spread the field props to connect with react-hook-form
                                                    error={!!errors.model}
                                                    helperText={errors.model ? errors.model.message : ''}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div>
                                        <Controller
                                            name="color"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    id="color"
                                                    type="text"
                                                    label={lang.color}
                                                    variant="outlined"
                                                    fullWidth
                                                    {...field} // Spread the field props to connect with react-hook-form
                                                    error={!!errors.color}
                                                    helperText={errors.color ? errors.color.message : ''}
                                                />
                                            )}
                                        />
                                    </div>


                                    <div>
                                        <Controller
                                            name="mileage"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    id="mileage"
                                                    type="text"
                                                    label={lang.mileage}
                                                    variant="outlined"
                                                    fullWidth
                                                    {...field} // Spread the field props to connect with react-hook-form
                                                    error={!!errors.mileage}
                                                    helperText={errors.mileage ? errors.mileage.message : ''}
                                                />
                                            )}
                                        />
                                    </div>



                                    <div className="cx-admin-footer-actions">
                                        {vehicleId === 'new' ? null : (
                                            <button
                                                type="button"
                                                className="cx-button cx-button-delete"
                                                tabIndex="-1"
                                                onClick={onDeleteConfirmClick}
                                                style={{ float: 'left' }}
                                            >
                                                {lang.delete}
                                            </button>
                                        )}
                                        <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
                                            {lang.cancel}
                                        </button>
                                        <button type="submit" className="cx-button cx-button-save">
                                            {lang.save}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <Dialog
                            open={openDeleteDialog}
                            onClose={handleDeleteDialogClose}
                            aria-labelledby="Vehicle-delete-dialog-title"
                        >
                            <DialogTitle id="Vehicle-delete-dialog-title">{lang.deleteVehicleConfirmation}</DialogTitle>
                            <DialogActions>
                                <button type="button" className="cx-button cx-button-cancel" onClick={handleDeleteDialogClose}>
                                    {lang.cancel}
                                </button>
                                <button type="button" className="cx-button cx-button-delete" onClick={onDeleteClick}>
                                    {lang.delete}
                                </button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </div>
        </>
    );
};

export default withRouter(VehicleEdit);
